import React from 'react'

const EmailDesigner = () => {
  return (
    <>
    <div>EmailDesigner</div>
    </>
    
  )
}

export default EmailDesigner