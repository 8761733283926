"use client";
import React from 'react'
import { Footer } from "flowbite-react";


const AppFooter = () => {
    return (
        <Footer container>
          <Footer.Copyright href="#" by="EMAIL ENCHANTER" year={2024} />
          <Footer.LinkGroup>
            <Footer.Link href="#">About</Footer.Link>
            
          </Footer.LinkGroup>
        </Footer>
      );
}

export default AppFooter
