import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React from 'react';
import HomePage  from "../pages/HomePage";
import NotFoundPage from "../pages/NotFoundPage";
import AboutPage from '../pages/AboutPage';
import EstimationToolPage from '../pages/EstimationToolPage';
import EmailDesigner from '../pages/EmailDesigner';
import LinksChecker from '../pages/LinksChecker';

const Index = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Exact match for root path */}
        <Route path="/" element={<HomePage />} />
        <Route path="/emaildesigner" element={<EmailDesigner />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/estimation" element={<EstimationToolPage />} />
        <Route path="/links-checker" element={<LinksChecker />} />
        {/* Catch-all route for 404 */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Index;