import "chart.js/auto";
import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import AppFooter from '../components/AppFooter';
import { Helmet } from 'react-helmet';
import axios from "axios";

const EstimationToolPage = () => {
  const [formData, setFormData] = useState({
    campaignName: null,
    numSections: 0,
    targetGroups: 1,
    languages: 1,
  });

  const [chartData, setChartData] = useState({
    labels: ['Content Writer', 'Designer', 'Development', 'Data Analyst', 'Campaign Executive'],
    datasets: [
      {
        label: 'Estimated Time',
        data: [0, 0, 0, 0, 0],
        backgroundColor: 'rgba(88, 80, 236, 0.4)',
        borderColor: 'rgba(88, 80, 236, 1)',
        borderWidth: 1,
      },
    ],
  });

  const [shareableLink, setShareableLink] = useState('');
  const [TotalHours, setTotalHours] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const campaign = params.get('campaign');
    const sections = params.get('sections');
    const tg = params.get('tg');
    const lang = params.get('lang');

    if (sections && tg && lang) {
      const updatedFormData = {
        ...formData,
        campaignName: campaign,
        numSections: parseInt(sections, 10),
        targetGroups: parseInt(tg, 10),
        languages: parseInt(lang, 10),
      };
      setFormData(updatedFormData);
      calculateTeamHours(updatedFormData);
    }
  }, [location.search]);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData, [name]: value };
    setFormData(updatedFormData);
    calculateTeamHours(updatedFormData);
  };
  const createAndShortLink = async (link) => {
    console.log(link)
    try {
      const response = await axios.get('https://is.gd/create.php', {
        params: {
          format: 'json',
          url: encodeURI(link),
        },
      });

      const shortenedLink = response.data.shorturl;
      console.log(shortenedLink)
      setShareableLink(shortenedLink);
    } catch (error) {
      console.error('Error shortening URL:', error);
    }
  };
  const handleFormSubmit =  (e) => {
    e.preventDefault();
    const link = `${window.location.origin}${window.location.pathname}?campaign=${encodeURIComponent(formData.campaignName)}&sections=${encodeURIComponent(formData.numSections)}&tg=${encodeURIComponent(formData.targetGroups)}&lang=${encodeURIComponent(formData.languages)}`;
    //await createAndShortLink(link);
    setShareableLink(link);
    navigate(`?campaign=${formData.campaignName}&sections=${formData.numSections}&tg=${formData.targetGroups}&lang=${formData.languages}`);
  };

  const calculateTeamHours = (updatedFormData) => {
    const { numSections, targetGroups, languages } = updatedFormData;
    const contentWriterHours = numSections * 0.3;
    const designerHours = numSections * 0.3;
    const developmentHours = numSections * 0.45 * languages;
    const dataAnalystHours = targetGroups * 0.8;
    const campaignExecutiveHours = targetGroups * 0.1;
    setTotalHours(contentWriterHours + designerHours + developmentHours + dataAnalystHours + campaignExecutiveHours);
    setChartData({
      labels: ['Content Writer', 'Designer', 'Development', 'Data Analyst', 'Campaign Executive'],
      datasets: [
        {
          label: 'Estimated Time',
          data: [contentWriterHours, designerHours, developmentHours, dataAnalystHours, campaignExecutiveHours],
          backgroundColor: [
            'rgba(88, 80, 236, 0.4)',  // Color for content writer
            'rgba(236, 80, 80, 0.4)',  // Color for designer
            'rgba(80, 236, 80, 0.4)',  // Color for development
            'rgba(236, 236, 80, 0.4)', // Color for data analyst
            'rgba(80, 236, 236, 0.4)', // Color for campaign executive
          ],
          borderColor: [
            'rgba(88, 80, 236, 1)',    // Border color for content writer
            'rgba(236, 80, 80, 1)',    // Border color for designer
            'rgba(80, 236, 80, 1)',    // Border color for development
            'rgba(236, 236, 80, 1)',   // Border color for data analyst
            'rgba(80, 236, 236, 1)',   // Border color for campaign executive
          ],
          borderWidth: 1,
        },
      ],
    });
  };


  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareableLink).then(() => {
      alert('Link copied to clipboard!');
    });
  };

  const convertToHoursAndMinutes = (decimalHours) => {
    const hours = Math.floor(decimalHours);
    const minutes = Math.round((decimalHours - hours) * 60);
    return `${hours}h ${minutes}m`;
  };

  return (
    <>
    <Helmet>
        <title>Email Campaign Development Estimation Tool</title>
        <meta name="description" content="Easily estimate the development time for your email campaigns with our comprehensive tool. Fill out the form and get a detailed breakdown of hours required by each team member including Content Writer, Designer, Development, Data Analyst, and Campaign Executive." />
      </Helmet>
      <Header />
      <section className="container bg-white dark:bg-gray-900 py-8 px-4 mx-auto max-w-screen-xl ">
        <div className="mx-auto py-8">
          <div className="flex flex-col lg:flex-row">
            {/* Form Section */}
            <div className="w-full lg:w-1/2 lg:pr-4">
              <h2 className="text-xl font-bold mb-4 text-gray-700">Fill the form to estimate</h2>
              <form onSubmit={handleFormSubmit}>
              <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Campaign/Email Name</label>
                  <input
                    type="text"
                    name="campaignName"
                    value={formData.campaignName}
                    onChange={handleFormChange}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Number of Sections</label>
                  <input
                    type="number"
                    name="numSections"
                    value={formData.numSections}
                    onChange={handleFormChange}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Target Groups</label>
                  <input
                    type="number"
                    name="targetGroups"
                    value={formData.targetGroups}
                    onChange={handleFormChange}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Languages</label>
                  <input
                    type="number"
                    name="languages"
                    value={formData.languages}
                    onChange={handleFormChange}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    className="w-full bg-indigo-600 border border-transparent rounded-md py-2 px-4 inline-flex justify-center items-center text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Generate Sharable Link
                  </button>
                </div>
              </form>
              {shareableLink && (
                <div className="mt-4">
                  <p className="text-sm text-gray-700">Sharable Link:</p>
                  <div className="flex items-center">
                    <input
                      type="text"
                      readOnly
                      value={shareableLink}
                      className="flex-grow p-2 border border-gray-300 rounded-md"
                    />
                    <button
                      onClick={copyToClipboard}
                      className="ml-2 bg-indigo-600 text-white p-2 rounded-md hover:bg-indigo-700"
                    >
                      Copy
                    </button>
                  </div>
                </div>
              )}
            </div>

            {/* Chart Section */}
            <div className="w-full lg:w-1/2 lg:pl-4 mt-8 lg:mt-0">
              {chartData && (
                <Bar
                  data={chartData}
                  options={{
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: function (context) {
                            const value = context.raw;
                            return convertToHoursAndMinutes(value);
                          },
                        },
                      },
                    },
                    scales: {
                      y: {
                        ticks: {
                          callback: function (value) {
                            return convertToHoursAndMinutes(value);
                          },
                        },
                      },
                    },
                  }}
                />
              )}
              { TotalHours &&
                <h4 className="text-lg font-bold my-4 text-gray-700">Total Hours: {convertToHoursAndMinutes(TotalHours)}</h4>
              }
            </div>
          </div>
        </div>
      </section>
      <section className="container py-8 mx-auto max-w-screen-xl">
        <div className="glassmorphism-card bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-lg p-6 shadow-lg">
          <h2 className="text-xl font-bold mb-4 text-gray-700">Hours Calculation Logic</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="p-4 bg-white bg-opacity-50 rounded-lg shadow-md">
              <h3 className="text-lg font-semibold text-gray-800">Content Writer</h3>
              <p className="text-gray-700">Number of sections * 0.3 Hour</p>
            </div>
            <div className="p-4 bg-white bg-opacity-50 rounded-lg shadow-md">
              <h3 className="text-lg font-semibold text-gray-800">Designer</h3>
              <p className="text-gray-700">Number of sections * 0.3 Hour</p>
            </div>
            <div className="p-4 bg-white bg-opacity-50 rounded-lg shadow-md">
              <h3 className="text-lg font-semibold text-gray-800">Development</h3>
              <p className="text-gray-700">Number of sections * 0.45 Hour * Languages</p>
            </div>
            <div className="p-4 bg-white bg-opacity-50 rounded-lg shadow-md">
              <h3 className="text-lg font-semibold text-gray-800">Data Analyst</h3>
              <p className="text-gray-700">Number of target groups * 0.1 Hour</p>
            </div>
            <div className="p-4 bg-white bg-opacity-50 rounded-lg shadow-md">
              <h3 className="text-lg font-semibold text-gray-800">Campaign Executive</h3>
              <p className="text-gray-700">Number of target groups * 0.1 Hour</p>
            </div>
            
          </div>
        </div>
      </section>
      <AppFooter />
    </>
  );
};

export default EstimationToolPage;
