"use client";
import React from 'react'
import { Avatar, Dropdown, Navbar } from "flowbite-react";
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <Navbar fluid rounded>
      <Link to="/">
      <Navbar.Brand>
        {/* <img src="/favicon.svg" className="mr-3 h-6 sm:h-9" alt="Samsung" /> */}
        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">✨EMAIL ENCHANTER</span>
      </Navbar.Brand>
      </Link>
      <div className="flex md:order-2">
        <Dropdown
          arrowIcon={false}
          inline
          label={
            <Avatar alt="User settings" img="https://i.pravatar.cc/100" rounded />
          }
        >
          <Dropdown.Header>
            <span className="block text-sm">Guest</span>
            <span className="block truncate text-sm font-medium">Guest User</span>
          </Dropdown.Header>
          {/* <Dropdown.Item>Dashboard</Dropdown.Item>
          <Dropdown.Item>Settings</Dropdown.Item>
          <Dropdown.Item>Earnings</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item>Sign out</Dropdown.Item> */}
        </Dropdown>
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse>
        <Link to="/emaildesigner">Email Designer</Link>
        <Link to="/estimation">Email Estimation Tool</Link>
        <Link to="/links-checker">Links Checker</Link>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header