import React from 'react';
import AppFooter from '../components/AppFooter';
import Header from '../components/Header';
import { Helmet } from 'react-helmet';

const LinksChecker = () => {
  return (
    <>
    <Helmet>
        <title>Email Campaign Development Estimation Tool</title>
        <meta name="description" content="Easily estimate the development time for your email campaigns with our comprehensive tool. Fill out the form and get a detailed breakdown of hours required by each team member including Content Writer, Designer, Development, Data Analyst, and Campaign Executive." />
      </Helmet>
      <Header />
      <main className="py-10 bg-white min-h-screen relative overflow-hidden">
      <div className="absolute top-1/3 left-1/4 transform -translate-x-1/2 w-2/3 h-2/3 bg-gradient-to-r from-blue-200 via-blue-300 to-purple-300 opacity-30 rounded-full blur-3xl"></div>
      
      <section className="container mx-auto text-center my-10 relative z-10">
        <h2 className="text-4xl font-bold mb-6">Discover Non-Relevant Samsung Links Finder</h2>
        <p className="text-gray-700 text-xl mb-10">An extension to streamline your email development experience by filtering out irrelevant Samsung links.</p>
        <div className="flex flex-wrap justify-center space-x-4">
          <div className="glassmorphism-card w-full md:w-1/3 p-6 m-4">
            <h3 className="text-2xl font-semibold mb-4">Easy to Use</h3>
            <p className="text-gray-700">Simply install the extension and start filtering out non-relevant links with a click.</p>
          </div>
          <div className="glassmorphism-card w-full md:w-1/3 p-6 m-4">
            <h3 className="text-2xl font-semibold mb-4">Country Specific</h3>
            <p className="text-gray-700">Customize link filtering based on your selected country for more accurate results.</p>
          </div>
          <div className="glassmorphism-card w-full md:w-1/3 p-6 m-4">
            <h3 className="text-2xl font-semibold mb-4">Quick Validation</h3>
            <p className="text-gray-700">Get instant results and enhance your productivity by focusing on relevant content.</p>
          </div>
        </div>
      </section>

      <section id="how-to-use" className="container mx-auto text-center my-10 relative z-10">
        <h2 className="text-4xl font-bold mb-6">How to Use</h2>
        <div className="glassmorphism-card w-3/4 mx-auto p-8 my-8">
          <ol className="list-decimal list-inside text-left text-gray-700 text-xl space-y-4">
            <li>Install the extension from the Chrome Web Store.</li>
            <li>Click on the extension icon to open the popup.</li>
            <li>Select your desired country from the list.</li>
            <li>Enable the tooltip option if you want to see attributes on hover.</li>
            <li>Click "Validate" to filter out non-relevant links.</li>
            <li>Review the results displayed in the popup.</li>
          </ol>
        </div>
      </section>

      <section id="benefits" className="container mx-auto text-center my-10 relative z-10">
        <h2 className="text-4xl font-bold mb-6">Benefits</h2>
        <div className="flex flex-wrap justify-center space-x-4">
          <div className="glassmorphism-card w-full md:w-1/3 p-6 m-4">
            <h3 className="text-2xl font-semibold mb-4">Error-Free HTML</h3>
            <p className="text-gray-700">Ensure that your HTML is free from errors with our thorough validation process.</p>
          </div>
          <div className="glassmorphism-card w-full md:w-1/3 p-6 m-4">
            <h3 className="text-2xl font-semibold mb-4">Consistency</h3>
            <p className="text-gray-700">Maintain consistency across your web content by filtering out non-relevant links.</p>
          </div>
          <div className="glassmorphism-card w-full md:w-1/3 p-6 m-4">
            <h3 className="text-2xl font-semibold mb-4">Fast & Easy Checking</h3>
            <p className="text-gray-700">Check your links quickly and easily, saving you time and effort.</p>
          </div>
          <div className="glassmorphism-card w-full md:w-1/3 p-6 m-4">
            <h3 className="text-2xl font-semibold mb-4">Correct Links, Correct Emails</h3>
            <p className="text-gray-700">By ensuring the links are correct, the associated emails are also accurate.</p>
          </div>
        </div>
      </section>
    </main>
    <AppFooter/>
    </>
  );
};

export default LinksChecker;
